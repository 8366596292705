import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import mixpanel from 'mixpanel-browser';


Sentry.init({
  dsn: "https://3bdd51712f36416bad650ed7842051a5@o4504814123286528.ingest.sentry.io/4504921014730752",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
mixpanel.init('b77551575535880d7c287c839eda0cca', {debug: false, track_pageview: true, persistence: 'localStorage'});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
