import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { AuthContext } from "../contexts/AuthContext";
import { requestLogout } from "../services/AuthService";
import SearchOverlay from "./searchOverlayComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faUser } from "@fortawesome/free-solid-svg-icons";
import "./navbarComponent.css";
import { UserLoginContextModel } from "../interface/UserInteface";

const NavbarComponent = () => {
  const { isAuthenticated, userEmailContext, setLoginDetails } =
    useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [activeTab, setActiveTab] = useState('home');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(userAgent));
  }, []);

  const buildDesktopLoginBlock = () => {
    if (isAuthenticated && !isMobile) {
      return (
        <>
          <Col xs={2} className="text-end" style={{ marginTop: "8px" }}>
            <Nav.Item>Hi {userEmailContext}</Nav.Item>
          </Col>
          <Col xs={1} className="text-start">
            <Nav.Link onClick={logoutSubmit}>Logout</Nav.Link>
          </Col>
        </>
      );
    }
    return (
      <>
        <Col
          xs={2}
          className="text-end d-block d-sm-block d-md-none"
          style={{ marginTop: "8px" }}
        >
          <Nav.Link href="/login">
            <FontAwesomeIcon icon={faUser} />
          </Nav.Link>
        </Col>
        <Col xs={1} className="text-end d-none d-sm-none d-md-block">
          <Nav.Link href="/login">Login</Nav.Link>
        </Col>
      </>
    );
  };

  const logoutSubmit = () => {
    requestLogout()
      .then(() => {
        const loginDetail: UserLoginContextModel = {
          email: "",
          loggedIn: false,
          token: "",
        };
        setLoginDetails(loginDetail);
      })
      .catch((error) => {
        console.error("logout error ->", error);
      });
  };

  const redirectToSearchPage = () => {
    if (searchTerm) {
      navigate(`/search/${searchTerm}`);
    } else {
      navigate("/home");
    }
  };

  const desktopNavbarOnClick = (tabName:string, route:string) => {
    const loginRequiredTab = ["myFavourite", "uploadedVideo", "watchedHistory", "uploadNew"]
    if(!isAuthenticated && loginRequiredTab.includes(tabName)){
      navigate("/login")
      setActiveTab("")
      return;
    }
    navigate(route)
    setActiveTab(tabName)
  }

  const getTabStyle = (tab: string) => {
    return tab === activeTab ? { borderBottom: 'solid', borderColor: 'orange' } : {};
  };

  const handleEnterSubmit = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      redirectToSearchPage();
    }
  };
  const buildDesktopNavbar = () => {
    return (
      <>
        <Navbar bg="dark" variant="dark">
          <Container style={{ color: "white", display: "block" }}>
            <Row>
              <Col xs={3}>
                <Navbar.Brand href="/home">PaoPorn</Navbar.Brand>
              </Col>
              {/* <Col className="d-none d-md-block d-lg-block d-xl-block d-xxl-block"> */}
              <Col>
                <InputGroup size="sm">
                  {/* <InputGroup.Text id="basic-addon3">
              <FontAwesomeIcon icon={faMagnifyingGlass} onClick={() => setModalVisible(true)}/>
            </InputGroup.Text> */}
                  <Form.Control
                    aria-label="SearchVideo"
                    aria-describedby="basic-addon2"
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                    onKeyUp={handleEnterSubmit}
                    placeholder="Search Paoporn"
                  />
                  <Button
                    className="d-none d-md-block d-lg-block d-xl-block d-xxl-block"
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={redirectToSearchPage}
                  >
                    Search
                  </Button>

                  <FontAwesomeIcon
                    className="text-end d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none"
                    style={{ marginTop: "8px", marginLeft: "10px" }}
                    icon={faMagnifyingGlass}
                    onClick={() => redirectToSearchPage}
                  />
                </InputGroup>
              </Col>
              {/* <Col className="text-end d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => setModalVisible(true)}
              />
              <SearchOverlay
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
              />
            </Col> */}
              {buildDesktopLoginBlock()}
            </Row>
          </Container>
        </Navbar>
        <Navbar bg="dark" variant="dark" className="pt-3 pb-3">
          <Container style={{ color: "white", display: "block" }}>
            <Row>
              <Col>
                <Navbar.Text
                style={getTabStyle('home')}
                  className="desktopNavText"
                  onClick={() => desktopNavbarOnClick("home","/home")}
                >
                  Top today
                </Navbar.Text>
              </Col>
              <Col>
                <Navbar.Text
                style={getTabStyle('hotMonthly')}
                  className="desktopNavText"
                  onClick={() => desktopNavbarOnClick("hotMonthly","/home/hm")}
                >
                  Hot monthly
                </Navbar.Text>
              </Col>
              <Col>
                <Navbar.Text
                style={getTabStyle('mostFavourite')}
                  className="desktopNavText"
                  onClick={() => desktopNavbarOnClick("mostFavourite","/home/mf")}
                >
                  Most favourite
                </Navbar.Text>
              </Col>
              <Col>
                <Navbar.Text
                style={getTabStyle('myFavourite')}
                  className="desktopNavText"
                  onClick={() => desktopNavbarOnClick("myFavourite","/userFav")}
                >
                  My favourite
                </Navbar.Text>
              </Col>
              <Col>
                <Navbar.Text
                style={getTabStyle('uploadedVideo')}
                  className="desktopNavText"
                  onClick={() => desktopNavbarOnClick("uploadedVideo","/videoUploaded")}
                >
                  Video uploaded
                </Navbar.Text>
              </Col>
              <Col>
                <Navbar.Text
                style={getTabStyle('watchedHistory')}
                  className="desktopNavText"
                  onClick={() => desktopNavbarOnClick("watchedHistory","/viewHistory")}
                >
                  Watch history
                </Navbar.Text>
              </Col>
              <Col>
                <Navbar.Text
                style={getTabStyle('uploadNew')}
                  className="desktopNavText"
                  onClick={() => desktopNavbarOnClick("uploadNew","/uploadNew")}
                >
                  Upload video
                </Navbar.Text>
              </Col>
              <Col>
                <Navbar.Text
                  style={getTabStyle('contact')}
                  className="desktopNavText"
                  onClick={() => desktopNavbarOnClick("contact","/contact")}
                >
                  Contact Us
                </Navbar.Text>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </>
    );
  };

  const buildMobileNavbar = () => {
    return (
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        bg="dark"
        variant="dark"
        expanded={expanded}
      >
        <Container fluid>
          <Row>
            <Col xs={3}>
              <Navbar.Brand href="/home" style={{ color: "white" }}>
                PaoPorn
              </Navbar.Brand>
            </Col>
            <Col>
              <InputGroup size="sm">
                <Form.Control
                  aria-label="SearchVideo"
                  aria-describedby="basic-addon2"
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                  onKeyUp={handleEnterSubmit}
                  placeholder="Search Paoporn"
                />
                <Button
                  className="d-none d-md-block d-lg-block d-xl-block d-xxl-block"
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={redirectToSearchPage}
                >
                  Search
                </Button>

                <FontAwesomeIcon
                  className="text-end d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none"
                  style={{ marginTop: "8px", marginLeft: "10px" }}
                  icon={faMagnifyingGlass}
                  onClick={() => redirectToSearchPage}
                />
              </InputGroup>
            </Col>
            <Col xs={3} className="justify-content-md-center">
              <Navbar.Toggle
                aria-controls="navbarScroll"
                onClick={() => setExpanded(!expanded)}
              />
            </Col>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "250px" }}
                navbarScroll
              >
                {!isAuthenticated && (
                  <Nav.Link href="/login">User login/register</Nav.Link>
                )}
                {isAuthenticated && (
                  <Navbar.Text>Welcome {userEmailContext}</Navbar.Text>
                )}
                <Navbar.Text onClick={() => mobileNavTextOnClick("/home")}>
                  Top today
                </Navbar.Text>
                <Navbar.Text onClick={() => mobileNavTextOnClick("/home/hm")}>
                  Hot monthly
                </Navbar.Text>
                <Navbar.Text onClick={() => mobileNavTextOnClick("/home/mf")}>
                  Most favourite
                </Navbar.Text>
                <Navbar.Text onClick={() => mobileNavTextOnClick("/userFav")}>
                  My favourite
                </Navbar.Text>
                <Navbar.Text
                  onClick={() => mobileNavTextOnClick("/videoUploaded")}
                >
                  Uploaded video
                </Navbar.Text>
                <Navbar.Text
                  onClick={() => mobileNavTextOnClick("/viewHistory")}
                >
                  Watch history
                </Navbar.Text>
                <Navbar.Text onClick={() => mobileNavTextOnClick("/uploadNew")}>
                  Upload new video
                </Navbar.Text>
                <Navbar.Text onClick={() => mobileNavTextOnClick("/contact")}>
                  Contact Us
                </Navbar.Text>
                {isAuthenticated && (
                  <Navbar.Text onClick={logoutSubmit}>Logout</Navbar.Text>
                )}
              </Nav>
            </Navbar.Collapse>
          </Row>
        </Container>
      </Navbar>
    );
  };

  const mobileNavTextOnClick = (path: string) => {
    navigate(path);
    setTimeout(() => {
      setExpanded(false);
    }, 150);
  };

  const build = () => {
    if (isMobile) return buildMobileNavbar();
    if (!isMobile) return buildDesktopNavbar();
  };

  return <>{pathname !== "/" && build()}</>;
};
export default NavbarComponent;
