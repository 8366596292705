import React, { useState } from "react";
import firebaseApp from "../firebase.config";
import mixpanel from "mixpanel-browser";
import axios from "axios";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmailContext, setUserEmailContext] = useState("");
  const [userTokenContext, setUserTokenContext] = useState("");

  const setLoginDetails = (data) => {
    setIsAuthenticated(data.loggedIn);
    setUserEmailContext(data.email);
    setUserTokenContext(data.token);
    if (data.loggedIn) {
      axios
        .get(`${process.env.REACT_APP_API_PATH}/UserSettings/GetUserByEmail`, {
          params: {
            email: data.email,
          },
        })
        .then((res) => {
          const clientDate = new Date();
          const utcDate = new Date(
            clientDate.getTime() + clientDate.getTimezoneOffset() * 60000
          )
            .toISOString()
            .replace("Z", "");
          mixpanel.identify(res.data.id);
          mixpanel.people.set({
            $created: `${res.data.signupAt}`,
            $email: `${res.data.email}`,
            LastLoginAtUtc: `${utcDate}`,
          });
        });
    } else {
      mixpanel.reset();
    }
  };

  const value = {
    isAuthenticated,
    userEmailContext,
    userTokenContext,
    setLoginDetails,
    firebaseApp,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
