import React from "react";
import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import Content from "./Content";

function App() {

  return (
    <AuthProvider>
      <Content />
    </AuthProvider>
  );
}

export default App;
