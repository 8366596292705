import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export const AgeConfirmPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Row
        className="justify-content-md-center"
        style={{ paddingTop: "30%", textAlign: "center" }}
      >
        <Col>
          <h4>
            You need to be at least 18 years to access this website. Please
            confirm below that you are at least 18 years.
          </h4>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} style={{ paddingTop: "30px", textAlign: "center" }}>
          <Button variant="danger" onClick={() => navigate("/home")}>
            I'm 18 or older. Enter
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AgeConfirmPage;
