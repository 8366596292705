import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { AuthContext } from "../contexts/AuthContext";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";

import { requestSignUp } from "../services/AuthService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserLoginContextModel } from "../interface/UserInteface";

export const SignupPage = () => {
  const [passwordMatched, setPasswordMatched] = useState<boolean>(true);
  const navigate = useNavigate();
  const { isAuthenticated, setLoginDetails } =
    useContext(AuthContext);

  const signupSubmit = (event: any) => {
    event.preventDefault();
    resetPasswordState();
    const email = event.target[0].value;
    const password = event.target[1].value;
    const confirmPassword = event.target[2].value;

    if (password !== confirmPassword) {
      setPasswordMatched(false);
      return;
    }

    if (!isPasswordValid(password)) {
      toast.error("Your password does not meet the security requirements", {
        position: "top-center",
        autoClose: 12000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    requestSignUp(email, password)
      .then((userCredential: any) => {
        const user = userCredential.user;
        if (user) {
          axios
            .put(
              `${process.env.REACT_APP_API_PATH}/UserSettings/CreateUser`,
              null,
              {
                params: {
                  email: user.email,
                },
              }
            )
            .then((res) => {
              const loginDetail:UserLoginContextModel = {email:user.email, loggedIn: true, token: userCredential.accessToken};
              setLoginDetails(loginDetail)
        
              navigate("/home");
              toast.success("signup success!", {
                position: "top-center",
                autoClose: 12000,
                hideProgressBar: false,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
              });
            })
            .catch((err) => {
              toast.error("Err!", {
                position: "top-center",
                autoClose: 12000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      })
      .catch((err: any) => {
        toast.error(signupErrorTranslator(err.code), {
          position: "top-center",
          autoClose: 12000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const resetPasswordState = () => {
    setPasswordMatched(true);
  };

  const signupErrorTranslator = (message: string): string => {
    switch (message) {
      case "auth/email-already-in-use":
        return "The email entered has already been registered.";
      case "auth/invalid-email":
        return "The email entered is an invalid email";
      default:
        return "Please refresh the sign up page and try again";
    }
  };

  const isPasswordValid = (password: string) => {
    const hasEightCharacters = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      hasEightCharacters &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSymbols
    );
  };

  const build = () => {
    if (isAuthenticated) {
      navigate("/home");
      return <></>;
    }
    return (
      <Container className="mt-5">
        <ToastContainer />
        <Row className="justify-content-md-center">
          <Col xs lg="3">
            <h3 className="text-center">Create account</h3>
            <Form onSubmit={signupSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  aria-describedby="passwordValidBlock"
                />
              </Form.Group>

              <Form.Text id="passwordValidBlock" muted>
                Please ensure your password:<br/>
                &bull; Has at least 8 characters<br/>
                &bull; Contains both uppercase and lowercase letters<br/>
                &bull; Includes at least one number<br/>
                &bull; Contains at least one symbol (e.g. !, @, #, $, %)
              </Form.Text>

              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  aria-describedby="passwordConfirmBlock"
                />
              </Form.Group>
              {!passwordMatched && (
                <Form.Text id="passwordConfirmBlock" muted>
                  * The password you entered does not match the confirmation
                  password. Please check and try again.
                </Form.Text>
              )}
              <br />
              <Form.Group>
                  <Button variant="primary" type="submit" style={{width:"100%"}}>
                    Sign Up
                  </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  };

  return build();
};
export default SignupPage;
