import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import axios from "axios";
import { VideoData } from "../interface/VideoDataInterface";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import style from "./Hompage.module.css";
import VideoBlockComponent from "../components/videoBlockComponent";

export const VideoSearchPage = () => {
  const [data, setData] = useState([] as VideoData[]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalVideos, setTotalVideos] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { searchTerm } = useParams();
  const [emptyResult, setEmptyResult] = useState(false);
  const [hotVideoData, setHotVideoData] = useState([] as VideoData[]);

  useEffect(() => {
    requestData();
  }, [searchTerm]);

  const requestData = () => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/VideoSettings/GetVideos`, {
        params: {
          currentPage: 1,
          getTotal: true,
          searchBy: searchTerm?.toLowerCase().trim(),
        },
      })
      .then((res) => {
        setData(res.data.result);
        setTotalVideos(res.data.totalCount);
        if (res.data.result.length < 1) {
          setEmptyResult(true);
          getHotVideos();
        } else {
          setEmptyResult(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const getHotVideos = () => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/VideoSettings/GetHotVideos`)
      .then((res) => {
        setHotVideoData(res.data.result);
      });
  };

  const buildEmptySearchResultBlock = () => (
    <>
      <br />
      <div>
        No video found by "<span style={{ color: "orange" }}>{searchTerm}</span>".
        Try searching by other keywords
      </div>
      <br />
      {hotVideoData.length > 0 && (
        <Row>
          <h3>Popular Videos</h3>
          {hotVideoData.map((ele, i) => {
            return (
              <VideoBlockComponent
                key={i}
                videoKey={ele.id}
                thumbnailUrl={ele.thumbnailPath}
                title={ele.title}
                duration={ele.duration}
                videoId={ele.id}
                views={ele.views}
                uploadDate={ele.createdAt}
              ></VideoBlockComponent>
            );
          })}
        </Row>
      )}
    </>
  );

  return (
    <Container>
      {emptyResult ? (
        buildEmptySearchResultBlock()
      ) : (
        <Row className="pt-7">
          {data.map((ele) => {
            return (
              <VideoBlockComponent
                key={ele.id}
                videoKey={ele.id}
                thumbnailUrl={ele.thumbnailPath}
                title={ele.title}
                duration={ele.duration}
                videoId={ele.id}
                views={ele.views}
                uploadDate={ele.createdAt}
              ></VideoBlockComponent>
            );
          })}
        </Row>
      )}
    </Container>
  );
};

export default VideoSearchPage;
