// Import the functions you need from the SDKs you need
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCIFls9h7zoQtnKX3T5LyC8hWcqVk75C_E",
  authDomain: "folkloric-clock-370102.firebaseapp.com",
  projectId: "folkloric-clock-370102",
  storageBucket: "folkloric-clock-370102.appspot.com",
  messagingSenderId: "972685997942",
  appId: "1:972685997942:web:c5287af0c6e5c157c3ec57"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const auth = getAuth(app);


export default firebaseApp;