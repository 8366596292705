import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { VideoBlockComponentProps } from "../interface/VideoDataInterface";
import styled from "styled-components";
// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";
import { faEye, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment-duration-format";
import { Row } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

const VideoTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
`;

export const VideoBlockComponent = (props: VideoBlockComponentProps) => {
    return (
    <Col
      xs={6}
      md={props.singlePerRow ? 12 : 6}
      lg={props.singlePerRow ? 12 : 3}
      className="pt-3"
    >
      <Card
        style={{
          background: "rgb(23, 23, 23)",
          border: "1.5px solid rgb(84, 43, 0)",
        }}
      >
        <Link
          to={`/video/${props.videoId}`}
          style={{ color: "white", textDecoration: "none" }}
          onClick={() => mixpanel.track("video clicked", {"videoId": `${props.videoId}`, "videoTitle":`${props.title}` })}
        >
          <div style={{ position: "relative" }}>
            <LazyLoadImage
              width={"100%"}
              effect="blur"
              src={props.thumbnailUrl}
              height={"100%"}
              style={{ maxHeight: 172 }}
            />
            <div
              style={{
                position: "absolute",
                right: "0",
                bottom: "0",
                zIndex: "55",
                backgroundColor: "black",
                color: "rgb(255,163,67)",
                fontSize: "14px",
                width: "95px",
                textAlign: "center"
              }}
            >
              <FontAwesomeIcon icon={faClock} /> {props.duration}
            </div>
          </div>

          <Card.Body style={{ padding: "10px 0 0 0", fontSize: "14px" }}>
            <Row>
              <Col xs={6}>
                <FontAwesomeIcon icon={faEye} /> {Math.ceil(props.views / 1000)}
                k
              </Col>
              <Col xs={6}>{moment(props.uploadDate).fromNow()}</Col>
            </Row>
            <Row>
              <VideoTitle style={{ color: "rgb(255,163,67)" }}>
                {props.title}
              </VideoTitle>
            </Row>
          </Card.Body>
        </Link>
      </Card>
    </Col>
  );
};

export default VideoBlockComponent;
