import firebaseApp from "../firebase.config";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const requestLogin = (email: string, password: string): Promise<any> => {
  const auth = getAuth(firebaseApp);
  return signInWithEmailAndPassword(auth, email, password)
};

const requestSignUp = (email: string, password: string): any => {
  const auth = getAuth(firebaseApp);
  return createUserWithEmailAndPassword(auth, email, password)
};

const requestLogout = (): Promise<any> => {
  const auth = getAuth(firebaseApp);
  return signOut(auth)
};

const requestUser = (): any => {
  const auth = getAuth(firebaseApp);
  const user = auth.currentUser;
  return user;
};

const requestAuth = ():any => {
  const auth = getAuth(firebaseApp);
  return auth;
}

export { requestLogin, requestLogout, requestSignUp, requestUser, requestAuth };
