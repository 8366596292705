// @ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import videojs from "video.js";
import "videojs-contrib-ads";
import { useParams } from "react-router-dom";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";
import "videojs-seek-buttons";
import "video.js/dist/video-js.css";
import "videojs-preroll-v2";
import "videojs-preroll-v2/dist/videojs-preroll.css";
import "videojs-overlay";
import "videojs-overlay/dist/videojs-overlay.css";
import axios from "axios";
import moment from "moment";
// This imports the functional component from the previous sample.
import VideoPlayerComponent from "../components/VideoPlayerComponent";
import VideoPlayerTestComponent from "../components/VideoPlayerTestComponent";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faHeart, faEye } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AuthContext } from "../contexts/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AdBannerComponent from "../components/adBannerComponent";
import adImageBanner from "../assets/ad_images/ad_5.jpg";
import AdColumnComponent from "../components/adColumnComponent";
import ad2Image from "../assets/ad_images/ad_2.jpg";
import VideoBlockComponent from "../components/videoBlockComponent";
import { VideoData } from "../interface/VideoDataInterface";

export const VideoPlayerPage = () => {
  const { isAuthenticated, userEmailContext, userTokenContext } =
    useContext(AuthContext);
  const playerRef = React.useRef<videojs.Player>(null);
  const [showSkipsAdButton, setSkipsAdButton] = useState<bool>(true);
  const [hotVideoData, setHotVideoData] = useState([] as VideoData[]);
  const [videoOption, setVideoOption] = useState<any>({
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
  });
  const [videoDetails, setVideoDetails] = useState<any>({
    views: 0,
    title: "",
    createdAt: "",
    thumbUps: 0,
  });
  const [isLoading, setIsLoading] = useState<bool>(true);
  const [isSavingData, setIsSavingData] = useState<bool>(false);
  const [isVideoFavourited, setIsFavourited] = useState<bool>(false);
  const [isVideoUpvoted, setVideoUpvoted] = useState<bool>(false);
  const { videoId } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/VideoSettings/GetVideoById`, {
        params: {
          id: videoId,
          email: userEmailContext ?? "",
        },
      })
      .then((res) => {
        const data = res.data;
        setVideoDetails({
          views: data.views,
          title: data.title,
          createdAt: data.createdAt,
          thumbUps: data.thumbUps,
        });
        setIsFavourited(data.isVideoInUserFavouriteList);
        if (data.cached) {
          setVideoOption({
            ...videoOption,
            sources: [
              {
                src: `https://paoporn.b-cdn.net/${videoId}/${
                  data.videoPath.substr(0, data.videoPath.lastIndexOf(".")) +
                  ".m3u8"
                }`,
                type: "application/x-mpegURL",
              },
            ],
          });
        } else {
          setVideoOption({
            ...videoOption,
            sources: [
              {
                src: `${process.env.REACT_APP_VIDEO_PATH}/${videoId}/${
                  data.videoPath.substr(0, data.videoPath.lastIndexOf(".")) +
                  ".m3u8"
                }`,
                // src: "https://stackvideo-testpermission.s3.amazonaws.com/test-video/encry.m3u8",
                type: "application/x-mpegURL",
              },
            ],
          });
        }
        getHotVideos(data.isDeletedFromSource ?? false);
      })
      .then(() => {
        setIsLoading(false);
      });

    updateVideoViews();
    updateVideoWatchHistory();

    window.scrollTo(0, 0);
  }, [videoId]);

  // setVideoIsReady(true);

  const skipAdCss = {
    position: "relative",
    top: "30%",
    left: "20%",
    zIndex: 99,
  };

  const setupVideoJsOptions = (data: any) => {
    // TODO check the video path to docker
    // fluid: true,
    // videoJsOptions.sources = [
    //   {
    //     src: `http://localhost:3000/output/${videoId}/${
    //       data.videoPath.substr(0, data.videoPath.lastIndexOf(".")) + ".m3u8"
    //     }`,
    //     type: "application/x-mpegURL",
    //   },
    // ];
    videoJsOptions.sources = [
      {
        src: "https://video-testing-stack.s3.ap-southeast-2.amazonaws.com/m3u8Test/test.m3u8",
        type: "application/x-mpegURL",
      },
    ];
  };

  const createSkipAdButtion = (player: videojs.Player) => {
    var adsInfo = document.createElement("div");
    adsInfo.className = "videojs-ads-info";

    var timeLeftInfo = document.createElement("span");
    timeLeftInfo.innerHTML = "5s to skip ad";
    adsInfo.appendChild(timeLeftInfo);

    // var skipButton = document.createElement('a');
    // skipButton.innerHTML = 'Skip Ad';
    // skipButton.onclick = this.skipButtonClicked.bind(this);
    // adsInfo.appendChild(skipButton);
    // this.skipButton = skipButton;

    player.el().appendChild(adsInfo);
  };

  const handlePlayerReady = (player: videojs.Player) => {
    playerRef.current = player;
    // createSkipAdButtion()

    // player.ads();

    // player.on('contentchanged', function() {
    //   // in a real plugin, you might fetch new ad inventory here
    //   player.trigger('adsready');
    // });

    player.controlBar.playToggle.on("click", function () {
      player.trigger("pauseButtonClick");
    });

    // player.on('readyforpreroll', function() {
    //   player.ads.startLinearAdMode();
    //   player.src('https://video-testing-stack.s3.ap-southeast-2.amazonaws.com/sample-5s.mp4');

    //   // send event when ad is playing to remove loading spinner
    //   player.one('adplaying', function() {
    //     videojs.log("add is playing");
    //     player.trigger('ads-ad-started');
    //   });

    //   // resume content when all your linear ads have finished
    //   player.one('adended', function() {
    //     player.ads.endLinearAdMode();
    //   });

    // });
    // player.trigger('adsready');

    // player.preroll.options.allowSkip = true;
    // player.preroll.options.showRemaining = true;
    // player.preroll.options.lang = {
    //   'skip':'12 Skip',
    //   'skip in': '12 Skip in ',
    //   'advertisement': '12 Advertisement',
    //   'video start in': '12 Video will start in: '
    // }

    // player.preroll({
    //   src: "https://video-testing-stack.s3.ap-southeast-2.amazonaws.com/sample-5s.mp4",
    //   allowSkip: true,
    //   showRemaining: true,
    //   skipTime: 2,
    // });

    // player.overlay({
    //   overlays: [
    //     {
    //       start: "waiting",
    //       end: "playing",
    //       content:
    //         "<img src='https://www.coe.int/documents/10518249/11065507/PSY+consultation.jpg/cfb1a023-2689-4ff8-a875-6974f6d4f0e2?t=1441372633000'/>",
    //       align: "top",
    //     },
    //     {
    //       start: "pauseButtonClick",
    //       end: "playing",
    //       content:
    //         "<img src='https://video-testing-stack.s3.ap-southeast-2.amazonaws.com/MOS-GMetrix-Suite.png'/>",
    //       align: "top",
    //     },
    //   ],
    // });

    // player.__proto__.handleTechPause_= function(ev) {
    //   if (!window.videojs.progressBarClicked) {

    //     this.removeClass('vjs-playing');
    //     this.addClass('vjs-paused');
    //     this.trigger('pause');
    //   }
    //  window.videojs.progressBarClicked = false;
    // }

    player.seekButtons({
      forward: 15,
      back: 10,
      backIndex: 0,
    });

    // You can handle player events here, for example:
    // player.on('contentchanged', function() {
    //   // in a real plugin, you might fetch new ad inventory here
    //   player.trigger('adsready');
    // });
    // player.on('readyforpreroll', function() {
    //   player.ads.startLinearAdMode();
    //   // play your linear ad content
    //   // in this example, we use a static mp4
    //   player.src('sample-5s.mp4');
    //   // player.src('../assets/sample-5s.mp4');

    //   // send event when ad is playing to remove loading spinner
    //   player.one('adplaying', function() {
    //     player.trigger('ads-ad-started');
    //   });

    //   // resume content when all your linear ads have finished
    //   player.one('adended', function() {
    //     player.ads.endLinearAdMode();
    //   });
    // });
  };

  const skipAd = () => {
    playerRef.current.ads.endLinearAdMode();
  };

  const renderTooltip = (content, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {content}
    </Tooltip>
  );

  const getHotVideos = (includeVideoDeletedFromSource: boolean) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/VideoSettings/GetHotVideos`, {
        params: {
          videoId: videoId,
          a: includeVideoDeletedFromSource
        },
      })
      .then((res) => {
        setHotVideoData(res.data.result);
      });
  };

  const updateFavouriteStatus = () => {
    if (!isAuthenticated) {
      toast.info("Please login prior to favourite this video", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (isVideoFavourited) {
      removeFromFavourite();
      setIsFavourited(false);
    } else {
      addToFaviourite();
      setIsFavourited(true);
    }
  };

  const addToFaviourite = () => {
    if (isSavingData) return;

    setIsSavingData(true);
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/UserSettings/AddUserFavourite`,
        null,
        {
          params: {
            email: userEmailContext,
            id: videoId,
          },
        }
      )
      .then(() => {
        toast.success("Video has been added into your favourite list", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => setIsSavingData(false));
  };

  const removeFromFavourite = () => {
    if (isSavingData) return;

    setIsSavingData(true);
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/UserSettings/RemoveUserFavourite`,
        null,
        {
          params: {
            email: userEmailContext,
            id: videoId,
          },
        }
      )
      .then(() => {
        toast.success("Video has been removed from your favourite list", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => setIsSavingData(false));
  };

  const updateVideoViews = () => {
    axios.post(
      `${process.env.REACT_APP_API_PATH}/VideoSettings/UpdateVideoViews`,
      null,
      {
        params: {
          id: videoId,
        },
      }
    );
  };

  const updateVideoThumbups = () => {
    if (isVideoUpvoted) {
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/VideoSettings/UpdateVideoThumbUps`,
        null,
        {
          params: {
            id: videoId,
          },
        }
      )
      .then(() => setVideoUpvoted(true));
  };

  const updateVideoWatchHistory = () => {
    if (!userTokenContext) return;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_PATH}/UserSettings/AddWatchHistory`,
      params: {
        email: userEmailContext,
        id: videoId,
      },
      headers: {
        Authorization: `Bearer ${userTokenContext}`,
        withCredentials: true
      },
    }).catch(err => console.log("err => ", err))
  };

  const thumbupCss = isVideoUpvoted ? { color: "orange" } : {};
  const favouriteCss = isVideoFavourited ? { color: "orange" } : {};

  return (
    <>
      <ToastContainer />
      {!isLoading && (
        <Container className="pt-5">
          {/* <VideoPlayerComponent options={videoJsOptions} onReady={handlePlayerReady} /> */}
          {/* {showSkipsAdButton && <button onClick={skipAd} style={skipAdCss}>Skip Ad</button>} */}
          {/* <button onClick={skipAd}>Skip Ad</button> */}

          <Row>
            <Col sm={12} lg={9}>
              {/* <AdBannerComponent
                adLocation={adImageBanner}
                alt="ad banner"
              ></AdBannerComponent>
              <AdBannerComponent
                adLocation={adImageBanner}
                alt="ad banner"
              ></AdBannerComponent> */}
              <VideoPlayerTestComponent
                options={videoOption}
                onReady={handlePlayerReady}
              />
            </Col>
            <Col lg={3} className="d-none d-sm-none d-md-none d-lg-block">
              {/* <img src={ad2Image} style={{ width: "100%" }}></img> */}
              <h3>Hot Videos</h3>
              {hotVideoData.slice(0, 2).map((ele, i) => {
                return (
                  <VideoBlockComponent
                    key={i}
                    videoKey={ele.id}
                    thumbnailUrl={ele.thumbnailPath}
                    title={ele.title}
                    duration={ele.duration}
                    videoId={ele.id}
                    views={ele.views}
                    uploadDate={ele.createdAt}
                    singlePerRow={true}
                  ></VideoBlockComponent>
                );
              })}
            </Col>
          </Row>

          <Row>
            <h3>{videoDetails.title}</h3>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              Uploaded {moment(videoDetails.createdAt).fromNow()}
              {/* | <FontAwesomeIcon icon={faThumbsUp} />{" "} 50%  */}
            </Col>
            <Col sm={12} md={6}>
              <Row>
                <Col xs={3} md={2}>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 150 }}
                    overlay={renderTooltip("Views")}
                  >
                    <div>
                      <FontAwesomeIcon icon={faEye} /> {videoDetails.views}
                    </div>
                  </OverlayTrigger>
                </Col>
                <Col xs={3} md={2}>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 150 }}
                    overlay={renderTooltip("Like")}
                  >
                    <div onClick={updateVideoThumbups}>
                      <FontAwesomeIcon style={thumbupCss} icon={faThumbsUp} />{" "}
                      {videoDetails.thumbUps}
                    </div>
                  </OverlayTrigger>
                </Col>
                <Col>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 150 }}
                    overlay={renderTooltip("Favourite")}
                  >
                    <div onClick={updateFavouriteStatus}>
                      <FontAwesomeIcon
                        style={isVideoFavourited ? { color: "orange" } : {}}
                        icon={faHeart}
                      />{" "}
                      {isVideoFavourited
                        ? "Video already favourited"
                        : "Add to favourite list"}
                    </div>
                  </OverlayTrigger>
                </Col>
                {/* <Col xs={3} md={2}>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 150 }}
                    overlay={renderTooltip("Report")}
                  >
                    <div>
                      <FontAwesomeIcon icon={faFlag} /> Report
                    </div>
                  </OverlayTrigger>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Row className="pt-7">
            {hotVideoData.slice(2).map((ele, i) => {
              return (
                <VideoBlockComponent
                  key={i}
                  videoKey={ele.id}
                  thumbnailUrl={ele.thumbnailPath}
                  title={ele.title}
                  duration={ele.duration}
                  videoId={ele.id}
                  views={ele.views}
                  uploadDate={ele.createdAt}
                  singlePerRow={false}
                ></VideoBlockComponent>
              );
            })}
          </Row>
        </Container>
      )}
    </>
  );
};
export default VideoPlayerPage;
