import Home from "./pages/HomePage";
import VideoPlayerPage from "./pages/videoPlayerPage";
import { LoginPage } from "./pages/loginPage";
import { SignupPage } from "./pages/signupPage";
import { AuthContext } from "./contexts/AuthContext";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useContext, useEffect } from "react";
import { requestUser } from "./services/AuthService";
import { VideoUploadPage } from "./pages/userSection/videoUploadPage";
import { NotFoundPage } from "./pages/notFoundPage";
import Navbar from "./components/navbarComponent";
import useFirebaseAuthentication from "./hooks/useFirebaseAuthentication";
import VideoSearchPage from "./pages/videoSearchPage";
import RandomTestPage from "./pages/testPage";
import styled from "styled-components";
import AgeConfirmPage from "./pages/ageConfirmPage";
import VideoCategoryPage from "./pages/videoCategoryPage";
import UserFavouriteVideoPage from "./pages/userSection/userFavouriteVideoPage";
import UserUploadedVideoPage from "./pages/userSection/userUploadedVideoPage";
import UserRecentlyViewed from "./pages/userSection/userRecentlyViewedPage";
import { UserLoginContextModel } from "./interface/UserInteface";
import { ContactPage } from "./pages/contactPage";
import { DeletedVideo } from "./pages/deletedVideoPage"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

function Content() {
  const { isAuthenticated, firebaseApp, setLoginDetails } =
    useContext(AuthContext);
  const auth = getAuth();
  const authUser = useFirebaseAuthentication(firebaseApp);

  const styles = {
    main_content: {
      color: "rgb(237, 237, 237)",
      background: "rgb(23, 23, 23)",
      minHeight: "100vh",
    },
  };

  useEffect(() => {
    // testData();
    const user = requestUser();
    // const auth = requestAuth();
    if (user) {
      const loginDetail: UserLoginContextModel = {
        email: user.email,
        loggedIn: true,
        token: user.accessToken,
      };
            setLoginDetails(loginDetail);
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User ss
      // ...
    } else {
      const loginDetail: UserLoginContextModel = {
        email: "",
        loggedIn: false,
        token: "",
      };
      setLoginDetails(loginDetail);
    }
  }, []);

  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    let location = useLocation();
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
  };

  return (
    <Router>
      <div className="Content" style={styles.main_content}>
        <Navbar />
        <Wrapper>
          <Routes>
            <Route path="/" element={<AgeConfirmPage />}></Route>
            <Route path="/home/" element={<Home />}></Route>
            <Route
              path="/home/:category"
              element={<VideoCategoryPage />}
            ></Route>
            <Route path="/video/:videoId" element={<VideoPlayerPage />}></Route>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/signup" element={<SignupPage />}></Route>
            <Route path="/contact" element={<ContactPage/>}></Route>
            <Route path="/deleted123" element={<DeletedVideo/>}></Route>
            <Route
              path="/uploadNew"
              element={
                <RequireAuth>
                  <VideoUploadPage />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/userFav"
              element={
                <RequireAuth>
                  <UserFavouriteVideoPage />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/viewHistory"
              element={
                <RequireAuth>
                  <UserRecentlyViewed />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/videoUploaded"
              element={
                <RequireAuth>
                  <UserUploadedVideoPage />
                </RequireAuth>
              }
            ></Route>
            <Route path="/"></Route>
            <Route
              path="/search/:searchTerm"
              element={<VideoSearchPage />}
            ></Route>
            <Route path="/randomTest" element={<RandomTestPage />}></Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Wrapper>
      </div>
    </Router>
  );
}
export default Content;
