// @ts-nocheck

import React, { useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";
import "videojs-seek-buttons";
import "videojs-contrib-ads";

export const VideoPlayerTestComponent = (props: any) => {
  const videoRef = React.useRef<HTMLDivElement>(null);
  const playerRef = React.useRef<videojs.Player | null>(null);
  const { options, onReady } = props;
  const { playAds, setPlayAds } = useState(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");

      videoRef.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);
      }));
      if (playAds) {
        player.ads();

        player.on("contentchanged", function () {
          // in a real plugin, you might fetch new ad inventory here
          player.trigger("adsready");
        });

        player.on("readyforpreroll", function () {
          player.ads.startLinearAdMode();
          // play your linear ad content
          // in this example, we use a static mp4
          player.src(
            "https://video-testing-stack.s3.ap-southeast-2.amazonaws.com/sample-5s.mp4"
          );

          // send event when ad is playing to remove loading spinner
          player.one("adplaying", function () {
            player.trigger("ads-ad-started");
          });

          // resume content when all your linear ads have finished
          player.one("adended", function () {
            player.ads.endLinearAdMode();
          });
        });

        // in a real plugin, you might fetch ad inventory here
        player.trigger("adsready");
      }

      // if (isMobile) {
      //   player.on("touchstart", () => {
      //     const playPromise = player.paused() ? player.play() : player.pause();

      //     if (playPromise !== undefined) {
      //       playPromise.catch((error) => {
      //         console.error("Error during play/pause:", error);
      //       });
      //     }
      //   });
      // }

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    }
    // else {
    //   const player = playerRef.current;

    //   player.autoplay(options.autoplay);
    //   player.src(options.sources);
    // }

    return () => {
      const player = playerRef.current;
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  // React.useEffect(() => {
  //   const player = playerRef.current;

  //   return () => {
  //     if (player && !player.isDisposed()) {
  //       player.dispose();
  //       playerRef.current = null;
  //     }
  //   };
  // }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoPlayerTestComponent;
