import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContactByEmailModel } from "../interface/UserInteface";
import axios from "axios";
import { useState } from "react";

export const ContactPage = () => {
  const [shouldDisable, setDisable] = useState(false);
  const [lastTime, setLastTime] = useState(0);
  const sendEmailFrozeWindow = 60000;

  const submitForm = (event: any) => {
    event.preventDefault();
    const now: number = new Date().getTime();
    if (now - lastTime >= sendEmailFrozeWindow) {
      requestApi(event);
      setLastTime(now);
    } else {
      toast.dismiss();
      toast.error("Please try sending us an email later after a minute", {
        position: "top-center",
        autoClose: 30000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const requestApi = (event: any) => {
    setDisable(true);
        toast.dismiss();
    event.preventDefault();

    const data: ContactByEmailModel = {
      userContactEmail: event.target[0].value,
      subject: event.target[1].value,
      emailContent: event.target[2].value,
    };

    axios
      .post(`${process.env.REACT_APP_API_PATH}/Contact/ContactByEmail`, data)
      .then(() => {
        toast.success("We'll reach out as soon as we can!", {
          position: "top-center",
          autoClose: 12000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(err.response.data, {
          position: "top-center",
          autoClose: 12000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      }).finally(() => setDisable(false))

  };

  const buildForm = () => {
    return (
      <Container className="mt-5">
        <ToastContainer />
        <Row className="justify-content-md-center">
          <Col xs lg="6">
            <h3 className="text-center">Send us an email</h3>
            <Form onSubmit={submitForm}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address*</Form.Label>
                <Form.Control type="email" required />
                <Form.Text className="text-muted">
                  We'll use this email to contact you and never share your email
                  with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Email Subject*</Form.Label>
                <Form.Control required />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Email Body*</Form.Label>
                <Form.Control as="textarea" rows={10} required />
              </Form.Group>
              {shouldDisable ? (
                <Button
                  className="mt-3"
                  variant="primary"
                  type="submit"
                  style={{ width: "100%" }}
                  disabled
                >
                  Sending...
                </Button>
              ) : (
                <Button
                  className="mt-3"
                  variant="primary"
                  type="submit"
                  style={{ width: "100%" }}
                >
                  Send
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    );
  };

  return buildForm();
};
