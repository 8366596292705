import firebaseApp from "../firebase.config";
import React, { useEffect, useState, useContext } from "react";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const useFirebaseAuthentication = (firebase) => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() =>{
     const unlisten = getAuth(firebaseApp).onAuthStateChanged(
        authUser => {
          authUser
            ? setAuthUser(authUser)
            : setAuthUser(null);
        },
     );
     return () => {
         unlisten();
     }
  }, []);

  return authUser
}

export default useFirebaseAuthentication;