import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { VideoData } from "../interface/VideoDataInterface";
import VideoBlockComponent from "../components/videoBlockComponent";
import { useParams } from "react-router-dom";
import AdBannerComponent from "../components/adBannerComponent";
import adImageBanner from "../assets/ad_images/ad_5.jpg";
import PaginatorComponent from "../components/paginatorComponent";
import { VideoCategories } from "../interface/Enums";

export const VideoCategoryPage = () => {
  const [data, setData] = useState([] as VideoData[]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalVideos, setTotalVideos] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { category } = useParams();

  useEffect(() => {
    requestData();
  }, [category]);

  const getCategory = () => {
    if (category === "hm") {
      return VideoCategories.MonthlyHot;
    } else if (category === "mf") {
      return VideoCategories.MonthlyFav;
    } else {
      return;
    }

  }
  const requestData = () => {
    const categoryEnum =  getCategory()
    if(!categoryEnum) return;
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/VideoSettings/GetVideoByCategory`,
        {
          params: {
            currentPage: 1,
            videoCategories: categoryEnum,
          },
        }
      )
      .then((res) => {
        setData(res.data.result);
        setTotalVideos(res.data.totalCount);
      });
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    const categoryEnum =  getCategory()
    if(!categoryEnum) return;

    const selectedPageNumber = selectedItem.selected + 1;
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_PATH}/VideoSettings/GetVideoByCategory`, {
        params: {
          currentPage: selectedPageNumber,
          videoCategories: categoryEnum,
        },
      })
      .then((res) => {
        setData(res.data.result);
        setCurrentPage(selectedPageNumber);
      });
    setIsLoading(false);
  };

  const buildPaginator = () => {
    const numberOfPages = Math.floor(totalVideos / 20) + 1;
    return (
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <PaginatorComponent
            pageTotal={numberOfPages}
            handlePageClick={handlePageChange}
          ></PaginatorComponent>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Container style={{ overflow: "hidden" }}>
        {/* <AdBannerComponent
          adLocation={adImageBanner}
          alt="ad banner"
        ></AdBannerComponent>
        <AdBannerComponent
          adLocation={adImageBanner}
          alt="ad banner"
        ></AdBannerComponent>
        <AdBannerComponent
          adLocation={adImageBanner}
          alt="ad banner"
        ></AdBannerComponent> */}
        {isLoading && (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        {!isLoading && (
          <>
            <Row className="pt-7">
              {data.map((ele) => {
                return (
                  <VideoBlockComponent
                    key={ele.id}
                    videoKey={ele.id}
                    thumbnailUrl={ele.thumbnailPath}
                    title={ele.title}
                    duration={ele.duration}
                    videoId={ele.id}
                    views={ele.views}
                    uploadDate={ele.createdAt}
                  ></VideoBlockComponent>
                );
              })}
            </Row>
            <br />
            <br />
            {buildPaginator()}
          </>
        )}
      </Container>
    </>
  );
};

export default VideoCategoryPage;
