import styled from "styled-components";
import { PaginationComponentProps } from "../interface/VideoDataInterface";
import ReactPaginate from "react-paginate";
import './pagination.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const PaginationStyle = styled.div`
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 20,
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',    
`;

export const PaginatorComponent = (props: PaginationComponentProps) => {
  return (
    <PaginationStyle className="paginatorComponent">
      <ReactPaginate
        activeClassName={'item active '}
        breakClassName={'item break-me '}
        breakLabel={'...'}
        containerClassName={'pagination'}
        disabledClassName={'disabled-page'}
        marginPagesDisplayed={2}
        nextClassName={"item next "}
        nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
        // style={{ fontSize: 18, width: 150 }}
        onPageChange={props.handlePageClick}
        pageCount={props.pageTotal}
        pageClassName={'item pagination-page '}
        pageRangeDisplayed={3}
        previousClassName={"item previous"}
        previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
      />
    </PaginationStyle>
  );
};

export default PaginatorComponent;
