import { useEffect, useState, useContext } from "react";
import { ProgressBar, Button, Form, Container } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import "moment-duration-format";
import { AuthContext } from "../../contexts/AuthContext";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const chunkSize = 1048576 * 3; //its 3MB, increase the number measure in mb

export const VideoUploadPage = () => {
  const [showProgress, setShowProgress] = useState(false);
  const [counter, setCounter] = useState(1);
  const [fileToBeUpload, setFileToBeUpload] = useState({} as any[]);
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [progress, setProgress] = useState(0);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null as any);
  const [imageInputKey, setImageInputKey] = useState(0);
  const [step, setStep] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [videoId, setVideoId] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const { isAuthenticated, userEmailContext } = useContext(AuthContext);

  const progressInstance = (
    <ProgressBar animated now={progress} label={`${progress}%`} />
  );

  useEffect(() => {
    if (fileSize > 0 && isAuthenticated && videoId) {
      setShowProgress(true);
      fileUpload();
    }
  }, [fileToBeUpload, progress]);

  const getFileContext = (e: any) => {
    const _file = e.target.files[0];

    var video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      if (video.duration < 2 || video.duration > 60 * 60 * 2) {
        toast.error(
          <div>
            Upload failed. <br />
            Video duration invalid
          </div>,
          {
            position: "top-center",
            autoClose: 12000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        return;
      }
      var duration = moment.duration(video.duration, "seconds");
      setVideoDuration(duration.format("hh:mm:ss", { trim: false }));
    };
    video.src = URL.createObjectURL(_file);

    setFileSize(_file.size);
    const _totalCount =
      _file.size % chunkSize == 0
        ? _file.size / chunkSize
        : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount);
    setFileToBeUpload(_file);
    const _fileID = uuidv4() + "." + _file.name.split(".").pop();
    setFileGuid(_fileID);
  };

  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk);
    }
  };

  const uploadChunk = async (chunk: any) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}/VideoSettings/UploadChunks`,
      chunk,
      {
        params: {
          id: counter,
          fileName: fileGuid,
        },
        headers: { "Content-Type": "application/json" },
      }
    );
    const data = response.data;
    if (data.isSuccess) {
      setBeginingOfTheChunk(endOfTheChunk);
      setEndOfTheChunk(endOfTheChunk + chunkSize);
      if (counter == chunkCount) {
        // console.log("Process is complete, counter", counter);
        await uploadCompleted();
      } else {
        var percentage = (counter / chunkCount) * 100;
        setProgress(parseInt(percentage.toFixed(0)));
      }
    } else {
      console.log("Error Occurred:", data.errorMessage);
    }
  };

  const uploadCompleted = async () => {
    var formData = new FormData();
    formData.append("fileName", fileGuid);
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}/VideoSettings/UploadComplete`,
      formData,
      {
        params: {
          fileName: fileGuid,
          type: videoId,
          duration: videoDuration,
        },
      }
    );
    const data = response.data;
    if (data.isSuccess) {
      setProgress(100);
      toast.success("Upload success!", {
        position: "top-center",
        autoClose: 12000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const uploadImageSubmit = () => {
    if (selectedImage && videoId && isAuthenticated) {
      axios
        .post(
          `${process.env.REACT_APP_API_PATH}/VideoSettings/UploadThumbnail`,
          selectedImage,
          {
            params: {
              fileName: uuidv4() + "." + selectedImage.name.split(".").pop(),
              type: videoId,
            },
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          resetImage();
        })
        .then((res) => {
          setStep(2);
        });
    }
  };

  const resetImage = () => {
    setSelectedImage(null);
    setImageInputKey(Date.now());
  };

  const onSelectedImageChange = (event: any) => {
    if (event.target.files) {
      if (
        ["image/jpg", "image/jpeg", "image/png"].includes(
          event.target.files[0].type
        )
      ) {
        setSelectedImage(event.target.files[0]);
      } else {
        toast.error(
          <div>
            Image format unsupported. <br /> Allowed format: <b>jpg</b>,
            <b>jpeg</b>, <b>png</b>
          </div>,
          {
            position: "top-center",
            autoClose: 12000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        return;
      }
    }
  };

  const uploadThumbnailElement = () => {
    return (
      <Container hidden={!(isAuthenticated && step === 1)}>
        <h1 className="mt-5 mb-3">Upload video thumbnail image</h1>

        {selectedImage && (
          <div>
            <img
              alt="not found"
              width={"250px"}
              src={URL.createObjectURL(selectedImage)}
            />
            <br />
            <button onClick={() => resetImage()}>Remove selected</button>
            <button onClick={() => uploadImageSubmit()}>
              Next step
            </button>
          </div>
        )}

        <br />
        <br />

        <Form.Group controlId="thumbnailImage" className="mb-3">
          <Form.Control
            type="file"
            name="myImage"
            key={imageInputKey}
            onChange={onSelectedImageChange}
          />
        </Form.Group>
      </Container>
    );
  };

  const uploadVideoElement = () => {
    return (
      <Container hidden={!(isAuthenticated && step === 2)}>
        <Form>
          <ToastContainer />
          <Form.Group controlId="formFile" className="mt-5">
            <h1 className="mt-5 mb-5">Upload video</h1>
            <Form.Control type="file" onChange={getFileContext} />
          </Form.Group>
          <Form.Group style={{ display: showProgress ? "block" : "none" }}>
            {progressInstance}
          </Form.Group>
        </Form>
      </Container>
    );
  };

  const uploadVideoIntroSubmit = (e: any) => {
    if (!isAuthenticated) return;
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/VideoSettings/UploadImageIntro`,
        {
        title: title,
        description: description,
        tag: tags,
        userEmail: userEmailContext,
        }
      )
      .then((res) => {
        setVideoId(res.data);
      })
      .then(() => {
        setStep(1);
      });
  };

  const uploadVideoIntroElement = () => {
    return (
      <Container hidden={!(isAuthenticated && step === 0)}>
        <h1 className="mt-5">Video intro</h1>
        <Form className="pt-3" onSubmit={uploadVideoIntroSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Video title:</Form.Label>
            <Form.Control
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tags:</Form.Label>
            <Form.Control
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Next step
          </Button>
        </Form>
      </Container>
    );
  };

  return (
    <>
      <ToastContainer />
      {<div hidden={isAuthenticated}>Please login first</div>}
      {/* {uploadVideoIntroElement()}
      {uploadThumbnailElement()}
      {uploadVideoElement()} */}
    </>
  );
};
export default VideoUploadPage;
