import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import { VideoData } from "../../interface/VideoDataInterface";
import VideoBlockComponent from "../../components/videoBlockComponent";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export const UserFavouriteVideoPage = () => {
  const { userEmailContext } = useContext(AuthContext);
  const [data, setData] = useState<VideoData[]>();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/UserSettings/GetUserFavouriteVideos`,
        {
          params: {
            email: userEmailContext,
          },
        }
      )
      .then((res) => {
        setData(res.data);
      });
  }, []);

  const build = () => {
    if (!data) return <></>;
    const videoBlocks = data.map((ele, i) => {
      return (
        <VideoBlockComponent
          key={ele.id}
          videoKey={ele.id}
          thumbnailUrl={ele.thumbnailPath}
          title={ele.title}
          duration={ele.duration}
          videoId={ele.id}
          views={ele.views}
          uploadDate={ele.createdAt}
        ></VideoBlockComponent>
      );
    });

    return (
      <Container>
        <Row>{videoBlocks}</Row>
      </Container>
    );
  };
  return build();
};

export default UserFavouriteVideoPage;
