import axios from "axios";
import React, { useEffect } from "react";
import { decryptData } from "../services/EncryptionService";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ad2Image from "../assets/ad_images/ad_2.jpg";
import ad3Image from "../assets/ad_images/ad_3.png";
import ad5Image from "../assets/ad_images/ad_5.jpg";
import AWS from "aws-sdk";
import PaginatorComponent from "../components/paginatorComponent";

const AdColumn = styled.div`
  padding-top: 100px;
  width: 15%;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AdImage = styled.img`
  width: 100%;
`;

const AdBanner = styled.img`
  height: 250px;
  width: 100%;
`;

export const RandomTestPage = () => {
  useEffect(() => {
    requestData();
  }, []);

  const requestData = () => {
    // axios
    //   .get(`${process.env.REACT_APP_API_PATH}/VideoSettings/GetEncryptData`)
    //   .then((res) => {
    //     console.log("raw API response => ", res.data);
    //     console.log("decrypted response => ", decryptData(res.data))
    //   })
    //   .catch((err) => console.error(err));
    return true;
  };


  return (
    // <Wrapper>
    //   <AdColumn className="d-none d-lg-block">
    //     <AdImage src={ad2Image} alt="ad 2" />
    //   </AdColumn>

    //   <Container>
    //     <Row>
    //       <AdBanner src={ad5Image} alt="ad 5" />
    //     </Row>
    //     <Row>This is main container</Row>
    //     <Row>This is main container</Row>
    //     <Row>This is main container</Row>
    //     <Row>This is main container</Row>
    //     <Row>This is main container</Row>
    //   </Container>

    //   <AdColumn className="d-none d-lg-block">
    //     <AdImage src={ad3Image} alt="ad 3" /> 
    //   </AdColumn>
    // </Wrapper>
    <Container>
      Gerrrrr
    </Container>
  );
};

export default RandomTestPage;
