import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { AuthContext } from "../contexts/AuthContext";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  requestLogin,
  requestLogout,
  requestUser,
} from "../services/AuthService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserLoginContextModel } from "../interface/UserInteface";

export const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setLoginDetails } = useContext(AuthContext);

  const loginSubmit = (event: any) => {
    event.preventDefault();
    const email = event.target[0].value;
    const password = event.target[1].value;

    requestLogin(email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        const loginDetail: UserLoginContextModel = {
          email: user.email,
          loggedIn: true,
          token: userCredential.user.accessToken
        };
        setLoginDetails(loginDetail);
        navigate("/home");
              })
      .catch((error) => {
        toast.error("Login failed. Please check email and password", {
          position: "top-center",
          autoClose: 12000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error("login error ->", error);
      });
  };

  const logoutSubmit = () => {
    requestLogout()
      .then(() => {
        const loginDetail: UserLoginContextModel = {
          email: "",
          loggedIn: false,
          token:""
        };
        setLoginDetails(loginDetail);
      })
      .catch((error) => {
        console.error("logout error ->", error);
      });
  };

  const userdataRequest = () => {

    // axios
    //   .get(`${process.env.REACT_APP_API_PATH}/Account/getuser`)
    //   // .post(`/Auth/login`, { user })
    //   .then((res) => {
    //     console.log("user => ", res);
    //   })
    //   .catch((err) => console.error("user error --> ", err));
  };

  const checkLogin = () => {
    if (isAuthenticated) {
      return <div>User logged in</div>;
    }
    return <div>No User</div>;
  };

  const build = () => {
    if (process.env.REACT_APP_SERVER_LOCATION === "German") {
      console.log("G");
    } else if (process.env.REACT_APP_SERVER_LOCATION === "US") {
      console.log("U");
    } else {
      console.log(process.env.REACT_APP_SERVER_LOCATION);
    }

    if (isAuthenticated) {
      navigate("/home");
      return <></>;
    }
    return (
      <Container className="mt-5">
        <ToastContainer />
        <Row className="justify-content-md-center">
          <Col xs lg="3">
            <h3 className="text-center">Login</h3>
            <Form onSubmit={loginSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
              <Form.Text className="text-muted" style={{ textAlign: "center" }}>
                Or create account at{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate("/signup")}
                >
                  {" "}
                  here{" "}
                </span>
              </Form.Text>
              <Button
                className="mt-3"
                variant="primary"
                type="submit"
                style={{ width: "100%" }}
              >
                Login
              </Button>
            </Form>
            <br />
          </Col>
        </Row>
      </Container>
    );
  };

  return build();
};
export default LoginPage;
